import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Title from "./components/title";
import Position from "./components/position";
import Description from "./components/description";
import PressLinks from "./components/pressLinks";
import Images from "./components/images";
import Image from "./components/image";

const ProjectTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  const { title, position, description, pressLinks, images } = frontmatter;
  return (
    <Fragment>
      <Title title={title} />
      <Position position={position} />
      <Images>
        {images
          ? images.map((image, index) => {
              return <Image key={`image-${index}`} image={image} />;
            })
          : null}
      </Images>
      <Description description={description} />
      <PressLinks pressLinks={pressLinks} />
    </Fragment>
  );
};

export const projectQuery = graphql`
  query projectQueryByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        description
        endDate
        institution
        location
        position
        startDate
        title
        pressLinks {
          pressLinkTitle
          pressLinkURL
        }
        images {
          image
          subTitle
          title
        }
      }
    }
  }
`;

export default ProjectTemplate;
