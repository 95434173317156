import React from "react";
import styled from "styled-components";
// import Img from "react-image";
import Image from "../../../components/image";

const Wrapper = styled.div`
  flex: 0 0 auto;
  margin-right: 1rem;
`;

const Information = styled.div``;

const Italic = styled.span`
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 400;
  font-style: italic;
`;

const StyledImage = styled(Image)`
  display: inline-block;
  height: 400px;
  max-height: 400px;
  border-radius: 5px;
`;

const ImageComponent = ({ image }) => {
  return (
    <Wrapper key={image.title}>
      <StyledImage src={image.image} />
      <Information>
        <p>{image.title}</p>
        <p>
          <Italic>{image.subTitle}</Italic>
        </p>
      </Information>
    </Wrapper>
  );
};

export default ImageComponent;
