import React from "react";
import styled from "styled-components";

const Description = styled.p`
  max-width: 600px;
  margin-bottom: 1rem;
`;

const DescriptionComponent = ({ description }) => {
  if (!description) {
    return null;
  }
  return <Description>{description}</Description>;
};

export default DescriptionComponent;
