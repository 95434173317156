import React, { Fragment } from "react";
import styled from "styled-components";

const PressLabel = styled.p`
  margin-bottom: 1rem;
`;
const PressLinks = styled.ul`
  margin-bottom: 2rem;
`;
const PressLink = styled.li``;

const PressLinksComponent = ({ pressLinks }) => {
  if (!pressLinks) {
    return null;
  }
  return (
    <Fragment>
      <PressLabel>Press</PressLabel>
      <PressLinks>
        {pressLinks.map((pressLink) => {
          return (
            <PressLink key={pressLink.pressLinkTitle}>
              <a
                href={pressLink.pressLinkURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {pressLink.pressLinkTitle}
              </a>
            </PressLink>
          );
        })}
      </PressLinks>
    </Fragment>
  );
};

export default PressLinksComponent;
