import styled from "styled-components"

const Images = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border-radius: 5px;
  flex-wrap: nowrap;
  overflow-x: auto;
  ${"" /* turn on momentum scrolling for ios */}
  ${"" /* -webkit-overflow-scrolling: touch; */}
  ${"" /* remove scrollbars */}
  &::-webkit-scrollbar {
    display: none;
  }
`

export default Images
