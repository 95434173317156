import React from "react";
import styled from "styled-components";

const Position = styled.p`
  margin-bottom: 2rem;
`;

const PositionComponent = ({ position }) => <Position>{position}</Position>;

export default PositionComponent;
