import React from "react";
import styled from "styled-components";

const Title = styled.h2`
  ${"" /* margin-bottom: 2rem; */}
`;

const TitleComponent = ({ title }) => <Title>{title}</Title>;

export default TitleComponent;
